let hamburger = document.getElementById("js-hamburger");
let nav = document.getElementById("js-nav");
let main = document.getElementById("main");
let navItems = document.querySelectorAll(".l-header__list-item");

hamburger.addEventListener("click", () => {
  main.classList.toggle("p-main-overlay");
  hamburger.classList.toggle("js-active-hamburger");
  nav.classList.toggle("js-active-nav");
});

navItems.forEach((e) => {
  e.addEventListener("click", () => {
    main.classList.remove("p-main-overlay");
    hamburger.classList.remove("js-active-hamburger");
    nav.classList.remove("js-active-nav");
  });
});
